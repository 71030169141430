'use client';

import { StoreDataFragment } from '@/gql';
import React, { ReactNode, createContext, useMemo } from 'react';

export type StoreData = {
  storeSlug: string;
  storeData: StoreDataFragment;
};

export const StoreDataContext = createContext<StoreData | undefined>(undefined);

export type StoreDataProviderProps = {
  children: ReactNode;
  storeSlug: string;
  storeData: StoreDataFragment;
};

export const StoreDataProvider: React.FC<StoreDataProviderProps> = ({ children, storeSlug, storeData }) => {
  const storeDataMemo = useMemo<StoreData>(() => {
    return { storeSlug, storeData };
  }, [storeData, storeSlug]);
  return <StoreDataContext.Provider value={storeDataMemo}>{children}</StoreDataContext.Provider>;
};
