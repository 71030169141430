/* eslint-disable no-underscore-dangle */
import { DEFAULT_FULFILLMENT_OPTION } from '@/constants';
import { Currency } from '@/gql';
import { safeUnreachable, StaticAssert, TypeEquals } from '@/lib/tsutils';
import {
  CurrencyDetail,
  FULFILLMENT_OPTIONS,
  FULFILLMENT_REGIONS,
  FulfillmentOption,
  FulfillmentOptionDetail,
  FulfillmentRegion,
  LegacyFulfillmentOption,
  PREFERENCE_SOURCES,
  PreferenceSource,
  RegionLut,
  RegionLutEntry,
} from '@/types/preferences';
import { isCurrency } from '../gql';

export const PREFERENCES_STATE_COOKIE = 'preferences-state'; // deprecated
export const USER_PREFERENCES_COOKIE = 'user-preferences';

// Currency values as array
export const CURRENCIES = ['AUD', 'CAD', 'EUR', 'GBP', 'USD'] as const;
// Compile time check that CurrencyValues contains exactly the values from Currency
// If this fails to build CurrencyValues no longer matches the values in Currency
type CurrencyT = StaticAssert<TypeEquals<Currency, (typeof CURRENCIES)[number]>>; // eslint-disable-line @typescript-eslint/no-unused-vars

// TODO: use dynamic currency/region info from rails keep hard coded (for now) and wait for new backend?
// rails-teespring/apps/commerce/app/controllers/commerce/api/v1/buyer_controller.rb (localization_details)
// teespring-custom-storefronts-react/src/redux/actions/index.js (fetchBuyerLocalization)

/**
 * Map currency to details
 */
export const CURRENCY_DETAILS: Record<Currency, CurrencyDetail> = {
  AUD: {
    currency: 'AUD',
    display: '$ AUD',
    symbol: '$',
    description: 'Australian Dollar',
  },
  CAD: {
    currency: 'CAD',
    display: '$ CAD',
    symbol: '$',
    description: 'Canadian Dollar',
  },
  EUR: {
    currency: 'EUR',
    display: '€ EUR',
    symbol: '€',
    description: 'Euro',
  },
  GBP: {
    currency: 'GBP',
    display: '£ GBP',
    symbol: '£',
    description: 'British Pound',
  },
  USD: {
    currency: 'USD',
    display: '$ USD',
    symbol: '$',
    description: 'United States Dollar',
  },
};

/**
 * Get details about a given currency
 *
 * @param currency
 * @returns CurrencyDetail for currency
 */
export function getCurrencyDetail(currency: Currency): CurrencyDetail {
  return CURRENCY_DETAILS[currency];
}

export const FULFILLMENT_OPTION_DETAILS: Record<FulfillmentOption, FulfillmentOptionDetail> = {
  AUSTRALIA: {
    option: 'AUSTRALIA',
    display: 'Australia',
    region: 'USA',
    flagCountry: 'AU',
  },
  CANADA: {
    option: 'CANADA',
    display: 'Canada',
    region: 'USA',
    flagCountry: 'CA',
  },
  EU: {
    option: 'EU',
    display: 'EU',
    region: 'EUR',
    flagCountry: 'EU',
  },
  INDIA: {
    option: 'INDIA',
    display: 'INDIA',
    region: 'IND',
    flagCountry: 'IN',
  },
  USA: {
    option: 'USA',
    display: 'USA',
    region: 'USA',
    flagCountry: 'US',
  },
  OTHER: {
    option: 'OTHER',
    display: 'Rest of the world',
    region: 'USA',
    flagCountry: '',
  },
};

/**
 * Get details about a fulfillment option
 *
 * @param option
 * @returns FulfillmentOptionDetail for option
 */
export function getFulfillmentDetail(option: FulfillmentOption): FulfillmentOptionDetail {
  return FULFILLMENT_OPTION_DETAILS[option];
}

// Upper case version of LegacyFulfillmentOption
type LegacyFulfillmentOptionUc = Uppercase<LegacyFulfillmentOption>;

/**
 * Case insensitve LegacyFulfillmentOption parser
 * @param value String value that may be a legacy fulfillment option
 * @returns
 */
export function parseLegacyFulfillmentOption(value: string): LegacyFulfillmentOption | null {
  const legacyOptionUc = value?.toUpperCase?.() as LegacyFulfillmentOptionUc;
  switch (legacyOptionUc) {
    case 'AUSTRALIA':
      return 'Australia';
    case 'CANADA':
      return 'Canada';
    case 'EU':
      return 'EU';
    case 'USA':
      return 'USA';
    case 'REST OF THE WORLD':
      return 'Rest of the world';
    default:
      // This wont compile if the above case statements aren't exhaustive for LegacyFulfillmentOptionUc, but
      // will return null if reached (and candidate isn't a LegacyFulfillmentOptionUc)
      return safeUnreachable(legacyOptionUc, null);
  }
}

/**
 * Convert LegacyFulfillmentOption values to FulfillmentOption, or null
 *
 * @param legacyOption
 * @returns
 */
export function legacyToFulfillmentOption(
  legacyOption: LegacyFulfillmentOption,
  defaultValue: FulfillmentOption = DEFAULT_FULFILLMENT_OPTION
): FulfillmentOption {
  const legacyOptionUc = legacyOption?.toUpperCase?.() as LegacyFulfillmentOptionUc;
  switch (legacyOptionUc) {
    case 'AUSTRALIA':
    case 'CANADA':
    case 'EU':
    case 'USA':
      // upper case legacy will do just fine for these
      return legacyOptionUc;
    case 'REST OF THE WORLD':
      return 'OTHER';
    default:
      // This wont compile if the above case statements aren't exhaustive for LegacyFulfillmentOptionUc, but
      // will return default if reached (and candidate isn't a LegacyFulfillmentOptionUc)
      return safeUnreachable(legacyOptionUc, defaultValue);
  }
}

/**
 * Type guard for FulfillmentRegion
 *
 * @param candidate The maybe a FulfillmentRegion to test
 * @returns true if the candidate appears to be a FulfillmentRegion
 */
export function isFulfillmentRegion(candidate: any): candidate is FulfillmentRegion {
  return FULFILLMENT_REGIONS.includes(candidate);
}

/**
 * Type guard for FulfillmentOption
 *
 * @param candidate The maybe a FulfillmentOption to test
 * @returns true if the candidate appears to be a FulfillmentOption
 */
export function isFulfillmentOption(candidate: any): candidate is FulfillmentOption {
  return FULFILLMENT_OPTIONS.includes(candidate);
}

/**
 * Type guard for PreferenceSource
 *
 * @param candidate The maybe a PreferenceSource to test
 * @returns true if the candidate appears to be a PreferenceSource
 */
export function isPreferenceSource(candidate: any): candidate is PreferenceSource {
  return PREFERENCE_SOURCES.includes(candidate);
}

/**
 * Convert region/currency to fulfillment option
 *
 * We persist option, but geo location gives us regions.  We currently only have two real regions, EU and USA
 * so need to use currency to tip our hand in picking the default fulfillment option
 *
 * @param fulfillmentRegion
 * @param currency
 * @returns FulfillmentOption
 */
export function getDefaultFulfillmentOption(
  fulfillmentRegion: FulfillmentRegion,
  currency: Currency,
  countryCodeRaw: string
): FulfillmentOption {
  // Special case for India, which currently isn't a complete
  // fulfillment region, so trigger off the raw country code
  if (countryCodeRaw === 'IN') {
    return 'INDIA';
  }

  // Default to the Europe option for European fullfiment
  if (fulfillmentRegion === 'EUR') {
    return 'EU';
  }

  // Use currency to tip hand on fulfillment option guess
  switch (currency) {
    case 'AUD':
      return 'AUSTRALIA';
    case 'CAD':
      return 'CANADA';
    default:
      return 'USA';
  }
}

/**
 * Type guard for RegionLutEntry
 *
 * @param candidate The maybe a RegionLutEntry to test
 * @returns true if the candidate appears to be a RegionLutEntry
 */
export function isRegionLutEntry(candidate: any): candidate is RegionLutEntry {
  const candidateEntry = candidate as RegionLutEntry;
  return Boolean(
    candidateEntry &&
      typeof candidate === 'object' &&
      isFulfillmentRegion(candidateEntry.reg) &&
      isCurrency(candidateEntry.cur)
  );
}

/**
 * Type guard for RegionLut
 *
 * @param candidate The maybe a RegionLut to test
 * @returns true if the candidate appears to be a RegionLut
 */
export function isRegionLut(candidate: any): candidate is RegionLut {
  const candidateLut = candidate as RegionLut;
  if (!candidateLut || typeof candidate !== 'object') {
    return false;
  }

  // make sure all values are entries
  const values = Object.values(candidateLut);
  return values.every(isRegionLutEntry);
}
