import React from 'react';

const ProductTileSkeleton: React.FC = () => {
  return (
    <div className='flex flex-col' data-testid='product-tile-skeleton'>
      <div className='relative aspect-h-1 rounded-md aspect-w-1 overflow-hidden bg-[#f6f6f8] animate-pulse w-full max-h-[300px] min-w-[170px] min-h-[170px]' />
      <div className='mt-4 w-full h-4 bg-[#f6f6f8] animate-pulse rounded-full' data-testid='product-info' />
      <div className='mt-1 w-full h-4 bg-[#f6f6f8] animate-pulse rounded-full' data-testid='product-info' />
      <div className='flex mt-2 items-center gap-2'>
        <div className='p-2 h-3 w-3 bg-[#f6f6f8] animate-pulse rounded-full' data-testid='product-color' />
        <div className='p-2 h-3 w-3 bg-[#f6f6f8] animate-pulse rounded-full' data-testid='product-color' />
        <div className='p-2 h-3 w-3 bg-[#f6f6f8] animate-pulse rounded-full' data-testid='product-color' />
        <div className='p-2 h-3 w-3 bg-[#f6f6f8] animate-pulse rounded-full' data-testid='product-color' />
        <div className='p-2 h-3 w-3 bg-[#f6f6f8] animate-pulse rounded-full' data-testid='product-color' />
      </div>
    </div>
  );
};

export default ProductTileSkeleton;
