import React from 'react';
import XIconRed from '@/components/Icons/XIconRed';
import ItemNoLongerAvailableIconRed from '@/components/Icons/ItemNoLongerAvailableIconRed';
import Link from 'next/link';

// eslint-disable-next-line no-shadow
export enum ToastErrorType {
  PageNotFound = '1',
  ItemNoLongerAvailable = '2',
  ColorNoLongerAvailable = '3',
  CartItemRemoved = '4',
  SizeNoLongerAvailable = '5',
}

export type ToastError = {
  id: ToastErrorType;
  title: string;
  description: React.ReactNode;
  icon: React.ReactNode;
};

const toastErrors: Record<ToastErrorType, ToastError> = {
  [ToastErrorType.PageNotFound]: {
    id: ToastErrorType.PageNotFound,
    title: 'Sorry, this product is history!',
    description: <>Please browse other products in my store.</>,
    icon: <XIconRed />,
  },
  [ToastErrorType.ItemNoLongerAvailable]: {
    id: ToastErrorType.ItemNoLongerAvailable,
    title: 'Item No Longer Available',
    description: (
      <>
        Check out other items with this design{' '}
        <Link href='/' className='underline text-blue-500'>
          here
        </Link>
      </>
    ),
    icon: <ItemNoLongerAvailableIconRed />,
  },
  [ToastErrorType.ColorNoLongerAvailable]: {
    id: ToastErrorType.ColorNoLongerAvailable,
    title: 'Color No Longer Available',
    description: (
      <>
        Check out other items with this design{' '}
        <Link href='/' className='underline text-blue-500'>
          here
        </Link>
      </>
    ),
    icon: <ItemNoLongerAvailableIconRed />,
  },

  [ToastErrorType.SizeNoLongerAvailable]: {
    id: ToastErrorType.SizeNoLongerAvailable,
    title: 'Size No Longer Available',
    description: (
      <>
        Check out other items with this design{' '}
        <Link href='/' className='underline text-blue-500'>
          here
        </Link>
      </>
    ),
    icon: <ItemNoLongerAvailableIconRed />,
  },

  [ToastErrorType.CartItemRemoved]: {
    id: ToastErrorType.CartItemRemoved,
    title: 'Item has been removed',
    description: <>One of the items in your cart is no longer available and has been removed.</>,
    icon: <XIconRed />,
  },
};

/**
 * Type guard for ToastErrorType
 * @param candidate
 * @returns
 */
export function isToastErrorType(candidate: any): candidate is ToastErrorType {
  return Boolean(candidate in toastErrors);
}

export default toastErrors;
