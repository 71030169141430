import { create } from 'zustand';
import toastErrors, { ToastErrorType } from '@/lib/toast-errors';

type ErrorState = {
  errors: string[];
  addError: (error: string) => void;
  removeError: (error: string) => void;
  clearErrors: () => void;
};

const useErrorState = create<ErrorState>((set) => ({
  errors: [],
  addError: (error: string) =>
    set((state) => {
      const errorKey = error as ToastErrorType;
      if (!state.errors.includes(error) && toastErrors[errorKey]) {
        return { errors: [...state.errors, error] };
      }
      return state;
    }),
  removeError: (error) => set((state) => ({ errors: state.errors.filter((e) => e !== error) })),
  clearErrors: () => set({ errors: [] }),
}));

export default useErrorState;
