import { Currency } from '@/gql';

/**
 * Details for a currency
 */
export type CurrencyDetail = {
  currency: Currency;
  display: string;
  symbol: string;
  description: string;
};

export const FULFILLMENT_REGIONS = ['EUR', 'USA', 'IND'] as const;
export type FulfillmentRegion = (typeof FULFILLMENT_REGIONS)[number];

export const FULFILLMENT_OPTIONS = ['AUSTRALIA', 'CANADA', 'EU', 'INDIA', 'USA', 'OTHER'] as const;
export type FulfillmentOption = (typeof FULFILLMENT_OPTIONS)[number];

export const PREFERENCE_SOURCES = ['default', 'set', 'detected'] as const;
export type PreferenceSource = (typeof PREFERENCE_SOURCES)[number];

// State data expected from preferences-state cookie, from
// retired preferences-state store
export type PreferencesState = {
  fulfillment: FulfillmentOption;
  currency: Currency;
  // internal usage
  currencySource: PreferenceSource;
  fulfillmentSource: PreferenceSource;
};

// Keep this as small as possible, it's persisted in a cookie
export type UserPreferences = {
  fulfillment?: FulfillmentOption;
  currency?: Currency;
};

/**
 * Details for a fullfilment option
 */
export type FulfillmentOptionDetail = {
  option: FulfillmentOption; // stable key, same a legacy store 'fulfillment_region' when capitalized
  display: string; // displayed string
  region: FulfillmentRegion; // the region for this option
  flagCountry: string; // the country code for the display flag
};

export const LEGACY_FULFILLMENT_OPTIONS = ['Australia', 'Canada', 'EU', 'USA', 'Rest of the world'] as const;
export type LegacyFulfillmentOption = (typeof LEGACY_FULFILLMENT_OPTIONS)[number];

/**
 * Entry in the local country to region/currency lookup table
 */
export type RegionLutEntry = {
  cur: Currency;
  reg: FulfillmentRegion;
};

/**
 * The LUT maps country code (two letter upper case to an entry)
 */
export type RegionLut = Record<string, RegionLutEntry>;
