import { StoreThemeFragment } from '@/gql';

export type PreviewDataHeader = { preview: boolean };
export type PreviewStoreTheme = PreviewDataHeader & Partial<StoreThemeFragment>;

// PreviewDataHeader type guard
export function isPreviewHeader(maybePreviewDataHeader: any): maybePreviewDataHeader is PreviewDataHeader {
  const previewDataHeader = maybePreviewDataHeader as PreviewDataHeader;
  return previewDataHeader && typeof previewDataHeader === 'object' && typeof previewDataHeader.preview === 'boolean';
}
