import { useEffect, useState } from 'react';
import { useLocationHash } from '@/hooks/use-location-hash';
import { hashToPreviewStoreTheme } from '@/lib/store-theme-preview';
import { PreviewStoreTheme, isPreviewHeader } from '@/types/store-theme-preview';

export const PREVIEW_STORE_THEME_STORAGE_KEY = 'storefront.preview.theme';

/**
 * Get the current session data for store theme, or null.  Will always return null on server (SSR)
 *
 * @returns
 */
function clientGetSessionStoreTheme(): PreviewStoreTheme | null {
  const cachedPreviewStoreTheme =
    typeof window !== 'undefined' && sessionStorage.getItem(PREVIEW_STORE_THEME_STORAGE_KEY);
  if (cachedPreviewStoreTheme) {
    const candidate = JSON.parse(cachedPreviewStoreTheme);
    if (candidate && isPreviewHeader(candidate)) {
      return candidate;
    }
  }
  return null;
}

/**
 * Store store preview theme state in session data, is a noop if called on the server (SSR)
 *
 * @param previewStoreTheme The store theme data to save
 */
function clientSaveSessionStoreTheme(previewStoreTheme: PreviewStoreTheme) {
  if (typeof window !== 'undefined') {
    sessionStorage.setItem(PREVIEW_STORE_THEME_STORAGE_KEY, JSON.stringify(previewStoreTheme));
  }
}

/**
 * Hook to return preview store theme.  As the preview theme may only be in the initial page load (as the preview can be navigated)
 * we stash the last seen value in session storage and use it if something new has not come along.
 *
 * @param immediateMode If true will check for store theme immediately, use only in cases where returned value won't affect
 * the immediate rendering, where it would break ssr/client rendering matching.  The default is to defer everything a tick
 * to make sure it happens past the first render.
 * @returns
 */
export const usePreviewStoreTheme = (immediateMode: boolean = false): PreviewStoreTheme | null => {
  const locationHash = useLocationHash(immediateMode);
  // Prime previewStoreTheme with parsed locationHash (could be valid in immediateMode),
  // or a cached session store theme. failing back to null if we can't do better
  // Adopting the hash (or session) immeditately in immediate mode
  const [previewStoreTheme, setPreviewStoreTheme] = useState<PreviewStoreTheme | null>(
    (immediateMode && (hashToPreviewStoreTheme(locationHash) || clientGetSessionStoreTheme())) || null
  );

  // Adopt session cache of preview store theme data in non-immediate mode
  // NOTE: This is a one shot init function, we don't want to re-trigger if immediateMode changes, so
  // we mute the exhaustive-deps lint rules, be careful when modifying the effect handler
  useEffect(() => {
    if (!immediateMode) {
      const cachedPreviewStoreTheme = clientGetSessionStoreTheme();
      if (cachedPreviewStoreTheme) {
        setPreviewStoreTheme(cachedPreviewStoreTheme);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Support hash changes, or adopting hash value in non-immediate mode
  // Note, will be called even in immediate mode, will likely be redundant in
  // immediate mode
  useEffect(() => {
    // Does this location hash contain preview store theme data?
    const candidatePreviewStoreTheme = hashToPreviewStoreTheme(locationHash);

    // If so, and it smells like preview data
    if (candidatePreviewStoreTheme && isPreviewHeader(candidatePreviewStoreTheme)) {
      // Lock it in
      setPreviewStoreTheme(candidatePreviewStoreTheme);

      // Stash it in session storage in case the hash gets removed during navigation
      clientSaveSessionStoreTheme(candidatePreviewStoreTheme);
    }
  }, [locationHash]);

  return previewStoreTheme;
};
