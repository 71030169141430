import { useState, useEffect, useCallback } from 'react';

interface WindowDimensions {
  width: number | null;
  height: number | null;
}

const useWindowDimensions = (): WindowDimensions => {
  const hasWindow: boolean = typeof window !== 'undefined';

  const getWindowDimensions = useCallback((): WindowDimensions => {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }, [hasWindow]);

  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>(getWindowDimensions());

  useEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions());
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
    return undefined;
  }, [getWindowDimensions, hasWindow]);

  return windowDimensions;
};

export default useWindowDimensions;
