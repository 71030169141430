import set from 'lodash-es/set';

// Packing unpacking utilities, primarily for preview data as passed from the dashboard

/**
 * Convert an object to a flat packed version
 *
 * from:
 * {
 *   a: {
 *     b: 5,
 *     c: 'c'
 *   }
 * }
 *
 * to:
 * {
 *  'a.b': 5,
 *  'a.c': 'c'
 * }
 *
 * @param object  Object to be flattened
 * @returns The flattened object
 */
export function flattenObject(object: Record<string, any>): Record<string, any> {
  const reducer = (accum: Record<string, any>, key: string, value: any, path: string = ''): void => {
    const newPath = `${path}${path ? '.' : ''}${key}`;
    if (typeof value === 'object' && value !== null) {
      Object.entries(value).forEach(([k, v]) => reducer(accum, k, v, newPath));
    } else {
      // eslint-disable-next-line no-param-reassign
      accum[newPath] = value;
    }
  };
  return Object.entries(object).reduce((accum, [k, v]) => {
    reducer(accum, k, v);
    return accum;
  }, {});
}

/**
 * Convert a flattened object to a `normal` hierarchical one
 *
 * from:
 * {
 *  'a.b': 5,
 *  'a.c': 'c'
 * }
 *
 * to:
 * {
 *   a: {
 *     b: 5,
 *     c: 'c'
 *   }
 * }
 *
 * @param object The object to un-flatten
 * @returns The data as a hierchical (non-flat) object
 */
export function unflattenObject(
  object: Record<string, any>,
  valuePredicate?: (value: any) => any
): Record<string, any> {
  const retVal: Record<string, any> = {};
  Object.entries(object).forEach(([key, value]) => {
    set(retVal, key, valuePredicate ? valuePredicate(value) : value);
  });
  return retVal;
}
