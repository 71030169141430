import { useContext } from 'react';
import { StoreData, StoreDataContext } from '@/contexts/store-data-context';

export const useStoreData = (): StoreData => {
  const storeData = useContext(StoreDataContext);
  if (storeData === undefined) {
    throw new Error('Error: useStoreData must be used within a StoreDataProvider');
  }
  return storeData;
};
