import {
  ORIGIN_DEFAULT,
  PAGEARG_DEFAULT,
  SEARCH_PARAM_PRODUCT_ID,
  SEARCH_PARAM_SIZE_ID,
  SEARCH_PARAM_VARIATION_ID,
} from '@/constants';
import { isNotNullish } from '@/lib/utils';
import { stripTrailingSlashes } from '@/lib/string';

export type ListingComponents = {
  listingSlug?: string;
  productId?: number;
  variationId?: number;
  sizeId?: number;
};

// sanity check for upper limit of page
export const PAGEARG_MAX = 1000000;

/**
 * Generates a listing page route (path segment of the client visible url)
 *
 * @param listingComponents
 * @returns Root relative pathname + query string to the specified listing page
 */
export const buildListingRoute = ({ listingSlug, productId, variationId, sizeId }: ListingComponents) => {
  if (!listingSlug) {
    return '';
  }

  const params = new URLSearchParams();
  if (isNotNullish(productId)) {
    params.set(SEARCH_PARAM_PRODUCT_ID, productId.toString());
  }
  if (isNotNullish(variationId)) {
    params.set(SEARCH_PARAM_VARIATION_ID, variationId.toString());
  }
  if (isNotNullish(sizeId)) {
    params.set(SEARCH_PARAM_SIZE_ID, sizeId.toString());
  }

  const paramsStr = params.toString();
  const paramsSuffix = paramsStr ? `?${paramsStr}` : '';
  return `/listing/${listingSlug}${paramsSuffix}`;
};

/**
 * Generates a collection page route (path segment of the client visible url)
 *
 * @param listingComponents
 * @returns Root relative pathname + query string to the specified listing page
 */
export const buildCollectionRoute = (collectionSlug: string) => {
  return `/${collectionSlug}`;
};

/**
 * Given available origin and storeslug, build the best store url we can, defaulting to the default storee
 *
 * @param origin Request origin, if known
 * @param storeSlug Store slug, if known
 * @returns The root url (w/o trailing slash) of the store
 */
export const buildEffectiveStoreUrl = (origin: string | undefined, storeSlug: string | undefined) => {
  // make sure orgin doesn't have any trailing slashes
  const normalizedOrigin = origin ? stripTrailingSlashes(origin) : undefined;
  return normalizedOrigin || (storeSlug && `https://${storeSlug}.creator-spring.com`) || 'https://creator-spring.com';
};

/**
 * Gets the product id from the pid (or product) query param.
 *
 * @param {String} url - Example: /es/listing-slug?tsmac=store&pid=624&sid=front
 * @returns {Integer} the product id or undefined if not found
 */
export const productIdFromUrl = (url: string) => {
  const value = parseInt(url?.match(/[?&](?:pid|product)=([^&]+)/)?.[1] || '', 10);
  return Number.isNaN(value) ? undefined : value;
};

/**
 * Encode origin to path safe (uri encoded) form
 *
 * @param origin The origin to encode, if empty or undefined the ORIGIN_DEFAULT
 * constant will be substituted to prevent empty path segment names.
 * @returns The encoded version of the origin
 */
export const encodeOriginForRoute = (origin?: string): string => {
  return encodeURIComponent(origin || ORIGIN_DEFAULT);
};

/**
 * Decode origin (as encoded by middleware).  Will return the origin, or empty
 * string if no origin provided (including the middleware default)
 *
 * @param encodedOrigin The origin, as encoded by the middleware
 * @returns
 */
export const decodeOriginForRoute = (encodedOrigin: string): string => {
  const decodedOrigin = decodeURIComponent(encodedOrigin);
  return (decodedOrigin !== ORIGIN_DEFAULT && decodedOrigin) || '';
};

/**
 * Helper to parse and validate a string (or undefined) as a page argument
 *
 * @param pageArg
 * @returns the validated page number (in range [1, PAGEARG_MAX]) or undefined
 */
export const parsePageArg = (pageArg: string | undefined): number | undefined => {
  // get pageArg as an number (NaN if invalid)
  const pageNum = (pageArg && +pageArg) || Number.NaN;
  // Return number version of page if we got an integer in range [1, PAGEARG_MAX], or undefined
  // Use Number.isInteger() to check for int-ness (conveniently exludes NaN/Infinity for us as well)
  return Number.isInteger(pageNum) && pageNum >= 1 && pageNum <= PAGEARG_MAX ? pageNum : undefined;
};

/**
 * Encode page arg for use in route segment.  This doesn't encode like
 * encodeOriginForRoute(), but keeping the naming convention.
 *
 * @param pageArg The page to encode, if empty, undefined, or not a
 * positive integer, the PAGEARG_DEFAULT constant will be returned
 * to prevent empty path segment names.
 * @returns The encoded version of the page argument
 */
export const encodePageArgForRoute = (pageArg: string | undefined): string => {
  // parse to validated page number, or undefined
  const pageNum = parsePageArg(pageArg);
  // Return string version of page num if we got an integer in range [1, PAGEARG_MAX], or PAGEARG_DEFAULT
  return pageNum ? pageNum.toString() : PAGEARG_DEFAULT;
};

/**
 * Decode page arg (as encoded by middleware).  Will return the validated numeric page num, or 1
 * if no page provided (including the middleware default)
 *
 * @param encodedPageArg The encoded page arg, as encoded by the middleware
 * @returns
 */
export const decodePageArgForRoute = (encodedPageArg: string): number => {
  return parsePageArg(encodedPageArg) || 1;
};
