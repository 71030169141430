import AES from 'crypto-js/aes';
import Utf8Encoder from 'crypto-js/enc-utf8';
import { PREVIEW_KEY } from '@/constants';
import { isPreviewHeader, PreviewStoreTheme } from '@/types/store-theme-preview';
import { unflattenObject } from '../flatten';

/**
 * Encrypt preview data through the same method as the dashboard
 *
 * @param previewData Raw preview data (as string)
 * @returns Encrypted preview data
 */
export function encryptPreviewData(previewData: string): string {
  return PREVIEW_KEY ? AES.encrypt(previewData, PREVIEW_KEY).toString() : previewData;
}

/**
 * Decrypt preview data through the same method as the dashboard
 *
 * @param previewData Encrypted preview data (as string)
 * @returns Unencrypted preview data
 */
export function decryptPreviewData(previewData: string): string {
  return PREVIEW_KEY ? AES.decrypt(previewData, PREVIEW_KEY).toString(Utf8Encoder) : previewData;
}

/**
 * Given a location hash, parse out the previewData meta quary argument
 *
 * @param hash The location hash string, empty or starting with '#'
 * @returns The preview data argument, or null if not found
 */
export function hashToPreviewData(hash: string): string | null {
  const previewData = hash?.match(/[#&]previewData=([^&]+)/)?.[1] || null;
  return (previewData && decodeURIComponent(previewData)) || null;
}

/**
 * Given a location hash, try to extract and the decode the preview data argument
 *
 * @param hash The location hash string, empty or starting with '#'
 * @returns A parsed object or null, assume it's full of viruses and toxic waste
 */
export function hashToRawPreviewData(hash: string): unknown {
  const encodedData = hashToPreviewData(hash);
  if (!encodedData) {
    return null;
  }

  const decodedData = decryptPreviewData(encodedData);
  if (!decodedData) {
    return null;
  }

  // The preview data is provided as an object with dot delimited paths to
  // values, let's convert that into a concrete object
  // {
  //   'meta.storeName': 'my-store-name',
  //   'dot.delimited.path': 'value',
  //   ...
  // }
  // to
  // {
  //   dot: {
  //     delimited: {
  //       path: 'value'
  //     }
  //  }
  //   meta: {
  //     storeName: 'my-store-name'
  //   }
  // }
  const parsedData = JSON.parse(decodedData);
  const previewData = unflattenObject(parsedData, (v) => (v !== 'null' ? v : ''));

  return previewData;
}

/**
 * Given a location hash, try to extract and the decode the PreviewStoreTheme
 *
 * @param hash The location hash string, empty or starting with '#'
 * @returns A PreviewStoreTheme object, or null if not found.  The PreviewStoreTheme has not been validated.
 */
export function hashToPreviewStoreTheme(hash: string): PreviewStoreTheme | null {
  const previewData = hashToRawPreviewData(hash);
  if (!previewData || !isPreviewHeader(previewData) || !previewData.preview) {
    return null;
  }

  // promote type with fingers crossed, in the belief it has store theme data in it
  return previewData as PreviewStoreTheme;
}
