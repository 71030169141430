'use client';

import { useEffect, useState } from 'react';

/**
 * Hook to track window.location.hash
 *
 * @param immediateMode If true will check for window hash immediately, use only in cases where returned value won't affect
 * the immediate rendering where it would break ssr/client rendering matching.  The default is to defer everything a tick
 * to make sure it happens past the first render.
 * @returns The current window.location.hash, or empty string if no hash.  Currently this is only helpful client side
 */
export const useLocationHash = (immediateMode: boolean = false): string => {
  const [locationHash, setLocationHash] = useState<string>(
    (immediateMode && typeof window !== 'undefined' && window?.location?.hash) || ''
  );

  useEffect(() => {
    const updateLocationHash = () => {
      setLocationHash(window.location.hash);
    };

    // prime the location hash
    updateLocationHash();

    // track hash changes
    window.addEventListener('hashchange', updateLocationHash);
    return () => {
      window.removeEventListener('hashchange', updateLocationHash);
    };
  }, []);

  return locationHash;
};
